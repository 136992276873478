import { useState } from 'react';
import Axios from "axios";
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import Login from '../login';
import { storage } from "../../componenets/firebase";
import { Button } from '@mui/material';

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
export default function CreateNews(){
  const logout = () => {
    localStorage.clear();
    redirect()
    window.location.reload();
  };
  const [image, setImage] = useState(null);
  const [logoUrl, setUrl] = useState(null);
  const id = JSON.parse(localStorage.getItem('userid'));
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleSubmit = () => {
    const createProduct = document.getElementById('createProduct');
    const logoLabel = document.getElementById('logoLabel');
    const fileInput = document.getElementById('fileInput');
    const file = document.getElementById('file');
    if (logoLabel !== null) {
      logoLabel.style.display = "none";
    }
    if (fileInput !== null) {
      fileInput.style.display = "none";
    }
    if (file !== null) {
      file.style.display = "none";
    }
    const imageRef = ref(storage, `brand+${Date.now()}`);
    uploadBytes(imageRef, image)
      .then(() => {
        getDownloadURL(imageRef)
          .then((logoUrl) => {
            setUrl(logoUrl);
          })
          .catch((error) => {
            console.log(error.message, "error getting the image url");
          });
        setImage(null);
      })
      .catch((error) => {
        console.log(error.message);
      });
      
    if (logoLabel !== null) {
      logoLabel.style.display = "none";
    }
    if (fileInput !== null) {
      fileInput.style.display = "none";
    }
    if (file !== null) {
      file.style.display = "none";
    }
    if (createProduct !== null) {
      createProduct.style.display = "flex";
    }
  };
  
    const [news, setNews] = useState([]);
    const [newsTitle, setNewstitle] = useState("");
    const [newsAbout, setnewsAbout] = useState("");
    const currentDate = new Date(Date.now());
    const day = currentDate.getDate();
    const month = currentDate.getMonth(); // Les mois commencent à 0 (janvier) jusqu'à 11 (décembre)
    const year = currentDate.getFullYear();
  
    // Tableau des noms de mois
    const monthNames = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
  
    const date = `${day} ${monthNames[month]} ${year}`;
    let navigate= useNavigate();

     function createBrands(){
    Axios.post("https://moozak-api.vercel.app/news",{newsTitle,newsAbout,date,logoUrl})
    .then((response) => {
        setNews([
          ...news,
          {
            newsTitle,
            newsAbout,
            date,
            logoUrl
          },
        ]);
       
      })
      .catch(error => {
        return error;
      });
      redirect()
    }
    async function redirect(){
      await navigate('/news');
      
    }
    return(
      <div > 
      
      {!id?(  <div >
            <Login/>
              </div>):(id==='64dd660778544c1617fdc764'?(
        <div className='createBrand'>
        <h2 className='signinTitle'>Create a New Feed</h2>
              <label for="fileInput" className='logoLabel' id="file">Add picture</label>
          
              <input type="file" onChange={handleImageChange} id="fileInput" />
      <button onClick={handleSubmit} className="logoLabel" id='logoLabel'>Upload Picture</button>
        <form className='createArticle' id='createProduct' onSubmit={createBrands}>
            
            <input required placeholder="News Title" type="text" className="textInput"
             onChange={(event) => {setNewstitle(event.target.value)}}/>
       
            <textarea required placeholder="News Text" type="text" className="textInput"  
          
            onChange={(event) => {setnewsAbout(event.target.value)}}></textarea>    
            <div>
            <Button type="submit" variant='contained' sx={{marginRight:2}}  className='commanderBtn'>Publish</Button>
        <Link to='/news'>  <Button type="submit" variant='contained' color='error'  className='commanderBtn'>Cancel</Button>
        </Link>
            </div>
        </form>
        <Button variant='contained' color='error' onClick={logout}  className='commanderBtn'>Log out</Button>
        </div>):(<></>)
              )
        }
        </div>
      
    )
}