// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBJ6kv6uUqPmgByTx-ldP6IMpWRYl07Ct4",
  authDomain: "handicraft-tunisia.firebaseapp.com",
  projectId: "handicraft-tunisia",
  storageBucket: "handicraft-tunisiaa",
  messagingSenderId: "371648673517",
  appId: "1:371648673517:web:1786d8afa32c61af4b3ee4",
  measurementId: "G-QVD03F7CG7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);


  
    