import Home from "./pages/home";
import Header from "./componenets/header";
import Menu from "./pages/menu";
import News from "./pages/news";
import CreateNews from "./pages/createNews";
import { Routes,Route,BrowserRouter } from 'react-router-dom';
function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Header />
       
        <Routes>
        <Route exact path="/" element={<Home/>} />
          <Route path="/menu" element={<Menu/>} />
          <Route path="/news" element={<News/>} />
          <Route path="/zakadmin" element={<CreateNews/>} />
          
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
