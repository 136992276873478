import './style.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@mui/material';
import Axios from "axios";
export default function Login(){
    const [user, setUser] = useState([]);
   
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [err, setErr] = useState("");
    let navigate= useNavigate();
    function verifUser(){
      const verifEmail=localStorage.getItem('email')
      if (verifEmail!==""){
       setEmail(verifEmail)
       setPassword("000000")
      }
    Axios.post("https://handicrafttunisia.vercel.app/api/auth/login",{email,password})
    .then((response) => {
        setUser([
          ...user,
          {
            email,
            password
          },
        ]);
        const users = response.data;
        localStorage.setItem('log', JSON.stringify(users.token));
        localStorage.setItem('userid', JSON.stringify(users.userId));
        localStorage.setItem('token', JSON.stringify(users.token));
       redirect();
      })
      
      .catch(error => {
          setErr(error.response.data.message);
      });
     
    };
    
    async function redirect(){
      await navigate('/zakadmin');
      window.location.reload()
      
    }
  
    return(
        <div className="signIn">
          
          <h2 className='loginTitle'>Log IN</h2>
            <input placeholder="Admin" type="email" required className="textInput"
            onChange={(event) => {setEmail(event.target.value)}}/>
            <input placeholder="Password" type="password" required="required" className="textInput"
            onChange={(event) => {setPassword(event.target.value)}}/>
    <Button  type="submit" variant="contained" size='small' color='info' id="modifier" sx={{fontSize :{xs:12,sm:12,md:14}}}   style={{fontFamily:'Geo',letterSpacing:1,marginTop:5 }}  onClick={verifUser} >log in</Button>
            {err && <div className='err'>{err}</div>}
          
        </div>
    )
}