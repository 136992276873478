import './style.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
export default function NewsCard({ id, newsTitle, newsAbout, date, logoUrl }) {
  const ident = JSON.parse(localStorage.getItem('userid'));
  async function sup(){
    await axios.delete("https://moozak-api.vercel.app/news/"+id._id)
    window.location.reload();
  }
  return ( 
    <div key={id._id}>    
    {!ident?(
       
      <Card sx={{ width:{ lg:'40vw',md:'80vw',sm:'85vw',xs:'85vw'}, display: 'flex', flexDirection: 'column', alignItems: 'center',marginTop:2,marginBottom:2 }} elevation={6} >
        <CardMedia
          sx={{ height: 350, width:{ lg:'40vw',md:'80vw',sm:'85vw',xs:'85vw'}, objectFit: 'cover' }}
          image={logoUrl}
          title="green iguana"
        />
        <CardContent sx={{ width:'80%', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }} >
          <Typography gutterBottom textTransform='capitalize' variant="h5" color='#E92839' textAlign='center' fontFamily='poppins' marginBottom={5}>
            {newsTitle}
          </Typography>
          <Typography variant="body2" color="text.primary" textAlign='center' fontSize={18} fontFamily='Itim' >
            {newsAbout}
          </Typography>
          <Typography variant="body2" color="text.primary" marginTop={4} textAlign='center' fontSize={18} fontFamily='Itim'>
            {date}
          </Typography>
        </CardContent>
      </Card>
   ):(ident==='64dd660778544c1617fdc764'?(<Card sx={{ width:{ lg:'40vw',md:'80vw',sm:'85vw',xs:'85vw'}, display: 'flex', flexDirection: 'column', alignItems: 'center',marginTop:2,marginBottom:2 }} elevation={6} >
   <CardMedia
     sx={{ height: 350, width:{ lg:'40vw',md:'80vw',sm:'85vw',xs:'85vw'}, objectFit: 'cover' }}
     image={logoUrl}
     title="green iguana"
   />
   <CardContent sx={{ width:'80%', padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }} >
     <Typography gutterBottom textTransform='capitalize' variant="h5" color='#E92839' textAlign='center' fontFamily='poppins' marginBottom={5}>
       {newsTitle}
     </Typography>
     <Typography variant="body2" color="text.primary" textAlign='center' fontSize={18} fontFamily='Itim' >
       {newsAbout}
     </Typography>
     <Typography variant="body2" color="text.primary" marginTop={4} textAlign='center' fontSize={18} fontFamily='Itim'>
       {date}
     </Typography>
     
   </CardContent>
   <Button variant='contained' onClick={sup} endIcon={<DeleteIcon/>} color='error' sx={{fontSize:{xs:'small',lg:'small'},padding:'4px'}}>Delete</Button>
 </Card>
    
    ):(<></>)
  )}
    </div>
  )
}

