import './style.css'
import breakfast from '../../images/menu1.jpg'
import tacos from '../../images/main-d.jpg'
import shake from '../../images/MILK_SHAKES-d.jpg'
import { Button } from '@mui/material'
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Contact from '../../componenets/contacts'
import Footer from '../../componenets/footer'
import { Link } from 'react-router-dom';
import ScrollPage from '../../componenets/scrollpageUp'
import React, { useState, useEffect, useRef } from 'react';

export default function Home() {
  const [hoverStates, setHoverStates] = useState([false, false]);
  const [showOrderButtonss, setShowOrderButtonss] = useState(false);

  const orderButtonRef = useRef(null);

  const toggleOrderButtonss = () => {
    setShowOrderButtonss(!showOrderButtonss);
  };

  const handleMouseEnter = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  };

  const handleClickOutside = (event) => {
    if (orderButtonRef.current && !orderButtonRef.current.contains(event.target)) {
     
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    if (showOrderButtonss) {
      document.addEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [showOrderButtonss]);

    function checkVisibility() {
        var images = document.querySelectorAll('.fade-in');
      
        for (var i = 0; i < images.length; i++) {
          var image = images[i];
          var imagePosition = image.getBoundingClientRect().top;
          var windowHeight = window.innerHeight;
      
          if (imagePosition < windowHeight) {
            image.classList.add('is-visible');
          }
        }
      }
    
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', checkVisibility);
      }
      
      const [isHovered1, setIsHovered] = useState(false);
    
      const handleMouseEnter1 = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave1 = () => {
        setIsHovered(false);
      };
   
    return(
        <div id='home'>
        <div className='home' >
            <div className='explore'>
                <div className='explorerText'>
            LET'S<br/> EXPLORE<br/> DELICIOUS<br/> FRENCH <br/>TACOS.
            </div>
            <div className='bandRed'></div>
           <a href='#about'><Button variant='contained'sx={{bgcolor:'#002494',fontSize:{xs:'small'}}}>discover more</Button></a> 
            
            </div>
        
        </div>
       
        <div className='aboutUs' id='about' > 
            <div className='presentation' >
            <h1 className='presentationTitle'>WELCOME TO MOOZAK</h1>
            <RestaurantIcon   sx={{color:'#E92839',fontSize:{xs:'24px',lg:'36px'}}}/>
            <p className='presentationParag'>Step into the world of French tacos, 
                where two delicious cultures come together in a mouthwatering fusion. 
                These "tacos lyonnais" are a blend of Mexican and French flavors that anyone can enjoy.
                 Imagine a big, tasty wrap filled with grilled meats,
                  crispy fries, melted cheese, and tasty sauces.
                   It's a unique mix of familiar tastes that create
                    an exciting and satisfying eating experience. 
                    Get ready to try something new and utterly delicious!</p>
                    <div className='presentationBtn'>
           <Link to='/menu'>
            <Button variant='contained'sx={{bgcolor:'#002494',fontSize:{xs:'small',lg:'large'}}}>BROWSE MENU</Button>
            </Link>
            <div className="orderButtonsContainer">
            <Button
            ref={orderButtonRef}
            variant='outlined'
            sx={{
              color: '#002494',
              borderColor: '#002494',
              fontSize: { xs: 'small', lg: 'large' }
            }}
            onClick={toggleOrderButtonss}
          >
            Order Online
          </Button>
          {showOrderButtonss && (
            <div className="orderOptionsContainer2">
              <a
                className='orderLink'
                href='https://deliveroo.co.uk/menu/london/holland-park/moozaks-62-kensington-high-street?utm_campaign=organic&utm_medium=referrer&utm_source=menu_share'
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='contained' sx={{ fontSize:{xs:'small',lg:'large'},color: '#fff', fontWeight: '600', background: '#E92839BB', marginTop: '4px' }}>
                  deliveroo
                </Button>
              </a>
              <a
                className='orderLink'
                href='https://www.ubereats.com/store/moozaks-french-tacos/oRr6Qwb5Vf--nc8Fnaxiwg?diningMode=DELIVERY'
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='contained' sx={{fontSize:{xs:'small',lg:'large'}, color: '#fff', fontWeight: '600', background: '#E92839BB', marginTop: '4px' }}>
                  Uber Eats
                </Button>
              </a>
            </div>
          )}
          </div>
                    </div>
            </div>
         
           <div onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}>       
          <div className={`menu ${isHovered1 ? 'show' : ''}`}  >
          <div className='menuItem'>
           <Link to='/menu' className='fade-in'>
        <div className="containerP">
          <img
            src={breakfast}
            alt="breakfast"
            className="menuPic"
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
          />
          {hoverStates[0] && <div className="projectOverlay grayBackground" 
          
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={() => handleMouseLeave(0)}>Breakfast Taco</div>}
        </div>
        </Link>

        <Link to='/menu' className='fade-in'>
        <div className="containerP">
          <img
            src={tacos}
            alt="tacos"
            className="menuPic"
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
          />
          {hoverStates[0] && <div className="projectOverlay grayBackground" 
          
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={() => handleMouseLeave(0)}>FRENCH TACO'S</div>}
        </div>
        </Link>

        <Link to='/menu' className='fade-in'>
        <div className="containerP">
          <img
            src={shake}
            alt="shake/juice"
            className="menuPic"
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={() => handleMouseLeave(0)}
          />
          {hoverStates[0] && <div className="projectOverlay grayBackground" 
          
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={() => handleMouseLeave(0)}>SHAKE & JUICE</div>}
        </div>
        </Link>
        </div>
        </div>
           </div>
           <hr className='seperation'></hr>
           <Contact/>
           <Footer/>
        </div>
   <ScrollPage/>
        </div>
    )
}