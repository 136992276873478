import UseGet from "../../useGet";
import MenuCard from "../MenuCard";
import './style.css'
export default function MenuItem(categ) {
 
  const {data,loading}= UseGet("https://moozak-api.vercel.app/menu");
  const data2=[];
  // eslint-disable-next-line array-callback-return
  data.map((bestArticle, index) => {
    if (bestArticle.cat===categ.categ){
      data2.push(bestArticle);
    }
  })
  return loading ? (
    <div className="center-content"><div className="loader"></div></div>
  ) : (

    data2.map((produit,index) => {
        return( <div key={produit.price+index+Math.floor(Math.random() * 100000)}> <MenuCard 
        id={produit._id}
            name={produit.name}
            composition={produit.compos}
            price={produit.price}
            categorie={produit.cat}
        />

     </div>
     ) }
  )
  )
}
