import './style.css'
import MenuItem from '../../componenets/menuItem'
import { Button } from '@mui/material'
import { useState } from 'react';
import ScrollPage from '../../componenets/scrollpageUp';
import { useMediaQuery } from 'react-responsive';
export default function Menu(){
    const [selectedCategory, setSelectedCategory] = useState("main");

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };
    const isMobile = useMediaQuery({ maxWidth: 768 }); // Modifier la largeur selon votre définition de "mobile"

    const desktopBackgroundImage = require(`../../images/${selectedCategory}-d.jpg`);
    const mobileBackgroundImage = require(`../../images/${selectedCategory}-d.jpg`);
  
    return(
       <div className='menuDiv'  style={{
        backgroundImage: `url(${isMobile ? mobileBackgroundImage : desktopBackgroundImage})`
      }} id='menu'>
        <div className="menus">
        </div>
        <div className='menuSlogan'>
        <h1 >Indulge in Flavorful Fusion:<br/> Discover the Artistry of Our Taco Creations!</h1>
        <a href='#menuItem'><Button variant='contained'sx={{bgcolor:'#002494'}}>show menu</Button></a> 
             </div>
               
             <div className='grid_content_menu'  id='menuItem'>
               <div className='menuAll'>                <div className='titleMenu'>
             <img width="32" height="32" src="https://img.icons8.com/stencil/32/000000/horizontal-line.png" alt="horizontal-line" style={{zIndex:99}}/>
             &nbsp;&nbsp; &nbsp;<h1 className='menuH1'>menu</h1>&nbsp;&nbsp;&nbsp;
             <img width="32" height="32" src="https://img.icons8.com/stencil/32/000000/horizontal-line.png" alt="horizontal-line"
             className='menuRotate'/>
             </div>
             <div className='btnMenu'>
             <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('main')}
                >
                    Main
                </Button>
             <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('make__taco')}
                >
                    MAKE YOUR TACO
                </Button>
             <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('SPECIAL_BOXES')}
                >
                    SPECIAL BOXES
                </Button>

                <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('SIDES')}
                >
                    SIDES
                </Button>
                <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('MILK_SHAKES')}
                >
                    MILK SHAKES
                </Button>
              <Button
                    variant='contained'
                    sx={{ bgcolor: '#002494',marginRight:1 }}
                    onClick={() => handleCategoryClick('SOFT_DRINKS')}
                >
                    SOFT DRINKS
                </Button>
             
            </div>
                            <MenuItem categ={selectedCategory} />

             </div>
             </div>

             <ScrollPage/>
             </div>
    )
}