import UseGet from "../../useGet";
import NewsCard from "../newCard";
export default function NewsItem() {
 
  const {data,loading}= UseGet("https://moozak-api.vercel.app/news");
  const news = Array.from(data).reverse();
 
  return loading ? (
    <div className="center-content"> <div className="loader"></div></div>
  ) : (

    news.map((produit,index) => {
        return( 
          <div  key={produit._id+index+Math.floor(Math.random() * 100000)} >
          <NewsCard  key={produit._id+index+Math.floor(Math.random() * 100000)}
        id={produit}
        newsTitle={produit.newsTitle}
        newsAbout={produit.newsAbout}
        date={produit.date}
        logoUrl={produit.logoUrl}
        />
</div>
     ) }
  )
  )
}
