import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import './style.css'
export default function ScrollPage(){
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return(
    <div className='topBtn'>
    <ArrowCircleUpIcon onClick={topFunction} fontSize='large' color='error' />
    </div>
  )
}