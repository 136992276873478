import './style.css';
import logo from '../../images/logo.png';
import { Button, Drawer, List, ListItem, ListItemText, IconButton,Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState,useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../scrollup';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
  const [activeLink, setActiveLink] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showOrderButtons, setShowOrderButtons] = useState(false);
  const [showOrderButton, setShowOrderButton] = useState(false);
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setDrawerOpen(false);
  };


  const orderButtonRef = useRef(null);

  const toggleOrderButtons = () => {
    setShowOrderButtons(!showOrderButtons);
  };



  const handleClickOutside = (event) => {
    if (orderButtonRef.current && !orderButtonRef.current.contains(event.target)) {
    
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    if (showOrderButtons) {
      document.addEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [showOrderButtons]);
  const toggleOrderButton = () => {
    setShowOrderButton(!showOrderButton);
  };
  return (
    <div className='header'>
      <ScrollToTop />
      <Grid container spacing={2}>
        <Grid item xs={5} sm={5} md={5}>
       <Link to='/'>   <img src={logo} alt='logo' className='logo' /></Link>
        </Grid>
        <Grid item xs={5} sm={5} md={5}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='end'
            onClick={() => setDrawerOpen(true)}
            
            sx={{ display: { xs: 'block', sm: 'block', md: 'block',lg:'none' },right:'20px',position:'absolute' }}
          >
            <MenuIcon fontSize='large' sx={{color:'white'}}/>
          </IconButton>
          <List
            component='nav'
            sx={{ display: { xs: 'none', sm: 'none', md: 'none',lg:'flex' }, gap: '20px',
            a: {
              fontFamily: 'poppins', 
              fontWeight:'600',
            } }}
          >
            <ListItem >
              <ListItemText>
                <Link
                  to='/'
                  className={activeLink === 'home' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('home')}
                >
                  Home
                </Link>
                </ListItemText>
                <ListItemText>
                <a id='abouts'
                  href='/#about'
                  className={activeLink === 'about' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('about')}
                >
                  About
                </a>
              </ListItemText>
              <ListItemText>
                <a
                  href='/#contact'  id='contacts'
                  className={activeLink === 'contact' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('contact')}
                >
                  Contact
                </a>
              </ListItemText>
              <ListItemText>
                <a
                 href='/menu'
                  className={activeLink === 'menu' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('menu')}
                >
                  Menu
                </a>
                </ListItemText>
                <ListItemText>
                <Link
                  to='/news'
                  className={activeLink === 'news' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('news')}
                >
                  News
                </Link>
                </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
        <div className="orderButtonsContainer">
        <Button
            ref={orderButtonRef}
            variant='outlined'
            sx={{
              color: '#fff',
              borderColor: '#fff',
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '10px',
            }}
            onClick={toggleOrderButtons}
          >
            Order Online
          </Button>
          {showOrderButtons && (
            <div className="orderOptionsContainer" >
              <a
                className='orderLink'
                href='https://deliveroo.co.uk/menu/london/holland-park/moozaks-62-kensington-high-street?utm_campaign=organic&utm_medium=referrer&utm_source=menu_share'
                target='_blank'
                rel='noreferrer'
                onClick={toggleOrderButtons}
              >
                <Button    variant='contained' sx={{ color: '#fff', fontWeight: '600', background: '#E92839BB', marginTop: '10px' }}>
                  deliveroo
                </Button>
              </a>
              <a
                className='orderLink'
                href='https://www.ubereats.com/store/moozaks-french-tacos/oRr6Qwb5Vf--nc8Fnaxiwg?diningMode=DELIVERY'
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='contained' sx={{ color: '#fff', fontWeight: '600', background: '#E92839BB', marginTop: '4px' }}>
                  Uber Eats
                </Button>
              </a>
            </div>
          )}
        </div>
      </Grid>
      </Grid>
      <Drawer   anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)} 
       PaperProps={{
        style: { backgroundColor: 'transparent' },
      }}>
        <List sx={{a: {
              fontFamily: 'poppins', 
              fontWeight:'500',
            } }} >
        <ListItem sx={{
            bgcolor: '#002594a8',
            // eslint-disable-next-line no-restricted-globals
            backdropFilter:blur(10),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%', 
            marginTop:'50px',
          }}>
              <ListItemText>
                <Link
                  to='/'
                  className={activeLink === 'home' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('home')}
                >
                  Home
                </Link>
                </ListItemText>
                <ListItemText>
                <a 
                  href='/#about'
                  className={activeLink === 'about' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('about')}
                >
                  About
                </a>
              </ListItemText>
              <ListItemText>
                <a
                  href='/#contact' 
                  className={activeLink === 'contact' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('contact')}
                >
                  Contact
                </a>
              </ListItemText>
              <ListItemText>
                <Link
                  to='/menu'
                  className={activeLink === 'menu' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('menu')}
                >
                  Menu
                </Link>
                </ListItemText>
                <ListItemText>
                <Link
                  to='/news'
                  className={activeLink === 'news' ? 'navLink active' : 'navLink'}
                  onClick={() => handleLinkClick('news')}
                >
                  News
                </Link>
                </ListItemText>
                
                <ListItemText>
                <Typography
                variant='body1'
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
                onClick={toggleOrderButton}
              >
            Order Online
          </Typography>
          </ListItemText>
          {showOrderButton && (
            <div className="orderOptionsContainer">
              <a
                className='orderLink'
                href='https://deliveroo.co.uk/menu/london/holland-park/moozaks-62-kensington-high-street?utm_campaign=organic&utm_medium=referrer&utm_source=menu_share'
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='contained' size='small' sx={{ color: '#fff', fontWeight: '600', background: '#E92839BB' }}>
                  deliveroo
                </Button>
              </a>
              <a
                className='orderLink'
                href='https://www.ubereats.com/store/moozaks-french-tacos/oRr6Qwb5Vf--nc8Fnaxiwg?diningMode=DELIVERY'
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='contained' size='small' sx={{ color: '#fff', fontWeight: '600', background: '#E92839BB', marginTop: '4px' }}>
                  Uber Eats
                </Button>
              </a>
            </div>
          )}
               
            </ListItem>
            
        </List>
      </Drawer>
    </div>
  );
}
