import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import './style.css';

export default function MenuCard({ id, name, price, composition }) {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    setShowChildren(true);
  }, []);

  const compositionWithLineBreaks = composition.replace(/ \\n /g, '<br>');
  const highlightedComposition = compositionWithLineBreaks.replace(/"([^"]+)":/g, '<span class="highlighted">$1:</span>');

  return (
    <div className='menuCard' key={id} >
      <h3 className='menuName'>{name}</h3>
      <span className={`composition ${showChildren ? 'fadeInChild' : ''}`} dangerouslySetInnerHTML={{ __html: highlightedComposition }} />
      {price && ( 
        <Button className={`priceMenu ${showChildren ? 'fadeInChild' : ''}`} variant='outlined' size='small' sx={{ color: '#002494', borderColor: '#002494' }}>
          £{price.toFixed(2)} 
        </Button>
      )}
    </div>
  );
}
