import './style.css'
import { Button } from '@mui/material'
import NewsItem from '../../componenets/newsItem'
import ScrollPage from '../../componenets/scrollpageUp'
export default function News(){
 
    return(
        <div>
        <div className="news">
                
        </div>
              <div className='newsWelcome'>
              <h1 >DISCOVER WHAT’S NEW AT MOOZAK</h1>
              <a href='#menuItem'><Button variant='contained'sx={{bgcolor:'#002494'}}>WHAT’S new?</Button></a> 
        </div>
        <div  id='menuItem'>
        <div className='grid_content' >
             <NewsItem/>
             </div>
        </div>
        <ScrollPage/>
        </div>
    )
    }